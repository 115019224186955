import React, { useEffect, useContext, useState } from 'react'
import { StateController } from './../../App'
// import ReCAPTCHA from 'react-google-recaptcha';

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import Input from '../atoms/Input'
import { sendDataLayer } from '../../utils/functions'

function CustomModal({
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  cta,
  redemtion,
  globalModal,
  position,
  push,
  info,
  termsOnModal,
  body,
  notFound,
  showErrorAuth
}) {
  const { setMdalOpen } = useContext(StateController)
  const [birthdate, setBirthdate] = useState('01-01-2000')
  // const [recaptchaValue, setRecaptchaValue] = useState(null)
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  // console.log(recaptchaValue, isButtonDisabled)

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: desc ,product_price: title, product_type: "inteligentes", index: position},repeat: true})
    }
    
    setShowModal(false)
    setBirthdate('')
  }

  const handleChangeDate = async (e) => {
    setBirthdate(e.target.value)
    
    if (primaryAction.setAuthDate) {
      primaryAction.setAuthDate(e.target.value)
    }
  }

  // const handleRecaptchaChange = (value) => {
  //   setRecaptchaValue(value);
  //   setIsButtonDisabled(!value);
  // };
  
  return (
    <div className={`
      mc-custom-modal 
      ${show && 'mc-custom-modal__opened'} 
      slow_ani 
      ${globalModal ? 'globalModal' : ''} 
      ${push ? 'modal-push' : ''} 
      ${info ? 'modal-info' : ''} 
      ${notFound ? 'modal-info' : ''}
    `}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            <div className="mc-custom-modal__close" onClick={close}>
              <Icon name="close" />
            </div>

            {redemtion &&
              <div className='mc-custom-modal__center--window__redemtion'>
                {image &&
                  <img  className="mc-custom-modal__center--window__imager" src={`/assets/images/${image}`} alt={title} />
                }
                <h3 className="mc-custom-modal__center--window__head">{desc}</h3>
                <div className="mc-custom-modal__center--window__title">Bono por: <strong>{title}</strong></div>
                <div className='mc-custom-modal__center--window__recapcha'>
                  {/* <ReCAPTCHA
                    sitekey="6Lew_LAqAAAAAFJmzLO21xUthTs_IXDFAuRCsl2i"
                    onChange={handleRecaptchaChange}
                  /> */}
                </div>
                <div className="mc-custom-modal__center--window__auth">
                  <h3>Validación de seguridad</h3>
                  <p>Para descargar su bono ingrese la siguiente información:</p>
                  <Input 
                    type={'birthdate'}
                    label={'Fecha de expedición cédula'}
                    placeholder={'dd/mm/aaaa'}
                    onChange={handleChangeDate}
                    inputByCharacter={false}
                    maxlength={50}
                    value={birthdate}
                    error={false}
                  />
                  {showErrorAuth &&
                    <div className="mc-custom-modal__center--window__auth--error">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 9V14" stroke="#ED1C27" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.9999 21.4098H5.93993C2.46993 21.4098 1.01993 18.9298 2.69993 15.8998L5.81993 10.2798L8.75993 4.99979C10.5399 1.78979 13.4599 1.78979 15.2399 4.99979L18.1799 10.2898L21.2999 15.9098C22.9799 18.9398 21.5199 21.4198 18.0599 21.4198H11.9999V21.4098Z" stroke="#C90C14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.9946 17H12.0036" stroke="#C90C14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      Fecha errónea, por favor verifique la información ingresada y vuelva a intentarlo.
                    </div>
                  }
                </div>
              </div>
            }
            {info &&
              <div className='mc-custom-modal__center--window__redemtion'>
                {image &&
                  <img  className="mc-custom-modal__center--window__imager" src={`/assets/images/${image}`} alt={title} />
                }
                <div className="mc-custom-modal__center--window__title"><strong>{title}</strong></div>
                <h3 className="mc-custom-modal__center--window__head">
                  <div dangerouslySetInnerHTML={{ __html: desc }} />
                </h3>
              </div>
            }
            {termsOnModal &&
              <div className='mc-custom-modal__center--window__redemtion'>
                {image &&
                  <img  className="mc-custom-modal__center--window__imager" src={`/assets/images/${image}`} alt={title} />
                }
                <div className="mc-custom-modal__center--window__title">
                  <strong>{title}</strong>
                  <span>pagando con su Tarjeta de Crédito Davivienda Mastercard.</span>
                </div>
                <div className='mc-custom-modal__center--window__scroll'>
                  <div dangerouslySetInnerHTML={{ __html: body }} />
                </div>
                
              </div>
            }
            <div className={`mc-custom-modal__center--window__actions ${redemtion ? 'redemtion' : ''}`}>
              {secondaryAction &&
                <Button
                  type="secondary"
                  text={secondaryAction.buttonText}
                  loading={false}
                  status="active"
                  onClick={secondaryAction.action}
                />
              }
              <Button
                className={termsOnModal ? 'btn-termsOnModal' : ''}
                type="primary"
                text={primaryAction ? primaryAction.buttonText : cta}
                loading={primaryAction ? primaryAction.loading : false}
                status={`active`}
                onClick={primaryAction ? primaryAction.action : close}
                icon={`${birthdate === '' ? (info || termsOnModal) ? '' : 'secure' : redemtion ? 'download' : ''}`}
              />
            </div>
            {terms &&
              <div className="mc-custom-modal__center--window__terms">
                <div className='custom-a'>
                  {terms.termsText}
                </div>
              </div>
            }

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal