import React, { useContext, useEffect, useState } from 'react'
import SliderRotator from '../organisms/SliderRotator'
import Container from '../../layouts/Container/Container'
import { StateController } from './../../App'
import Button from '../atoms/Button'

const SliderDavipuntosAwards = () => {

  const { staticContent } = useContext(StateController)
  const [davipuntos, setDavipuntos] = useState([])

  useEffect(() => {
    if (staticContent && staticContent.davipuntos) {
      setDavipuntos(staticContent.davipuntos)
    }
  }, [staticContent])
  return (
    <div className='mc-slider-davipuntos-awards'>
      <Container className={'davipuntos-awards-container'}>
        <SliderRotator
          variableWidth
          slidesToScroll={1}
          davipuntosAwards
          dots
          arrows
          mobileItemsToShow={1}
          desktopItemsToShow={3}
          className={'login-slider davipuntos-awards'}
          list={davipuntos}
          timer={5000}
        />
      </Container>
     <Container>
     <Button
        className={'fit-content btn-davipuntos-awards'}
        type="primary"
        text="Redima sus Davipuntos"
        loading={false}
        status="active"
        link="https://www.catalogodavipuntos.com/"
        external
      />
     </Container>
    </div>
  )
}

export default SliderDavipuntosAwards
