import React, { useRef, useState } from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'
import Icon from '../atoms/Icon';
import useWidth from '../../hooks/useWidth';

function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  customSliderStep,
  className,
  singleButton,
  arrows,
  dots,
  slidesToScroll,
  davipuntosAwards,
  variableWidth,
  notInfinityMobile,
  timer
}) {
  const sliderRef = useRef(null);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const { width } = useWidth();
  const settings = {
    dots: dots ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: slidesToScroll ? slidesToScroll : false,
    autoplay: false,
    autoPlaySpeed: 3000,
    arrows: arrows ? true : false,
    centerPadding: '20px',
    initialSlide: 0,
    variableWidth: (variableWidth && width > 992) ? true : false,
    afterChange: (index) => {      
      const lastSlide = sliderRef.current.props.children.length - 1;
      setIsLastSlide(index+1 === lastSlide);
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: notInfinityMobile ? false : true,
          dots: true,
          autoplay:true,
          autoPlaySpeed: 3000
        }
      }
    ]
  }  
  const handleNext = () => {    
    if (isLastSlide) {
      // sliderRef.current.slickPrev();
      sliderRef.current.slickGoTo(0);
    } else {
      sliderRef.current.slickNext(); 
    }
  };

  return (

    <>
    <div className={`mc-slider-rotator size-${list.length} step-${customSliderStep} speed-${timer}`}>
      <Slider ref={sliderRef} {...settings}>
        {list.map((slide, index) => {

          return (
            <div style={{width:'377px'}} key={index} className='mc-slider-rotator__slide'>
              <CardBox
                davipuntosAwards={davipuntosAwards}
                image={slide.image}
                imageDesktop={slide.imageDesktop}
                title={slide.title}
                desc={slide.desc}
                type={slide.type}
                className={className}

                id
                name={slide.name}
                description={slide.description}
                subdescription={slide.subdescription}
                double_points={slide.double_points}
                normal_points={slide.normal_points}
                logo={slide.logo}
                banner={slide.banner}
                color={slide.color}
              />
            </div>
          )
        })}
      </Slider>
    </div>
    {
        singleButton &&
        <button
          className={`single-button ${isLastSlide ? 'rotate' : ''}`}
          onClick={handleNext}
        >
          <Icon name='arrow-slider' />
        </button>

      }
    
    </>
  )
}

export default SliderRotator