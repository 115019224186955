import React, { useState, useEffect } from 'react'
import Container from '../../layouts/Container/Container'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button';
import { stQuiz } from '../../utils/helpers';
import { connect } from 'react-redux'

function Quiz({
  token,
  context
}) {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [emptyState, setEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response1, setResponse1] = useState('');
  const [response2, setResponse2] = useState('');
  const [response3, setResponse3] = useState('');

  const questions = [
    {
      question: '¿Qué tipo de paisaje prefiere al viajar?',
      options: [
        {
          image: 'quiz_1.png',
          option: 'Playas paradisíacas'
        },
        {
          image: 'quiz_2.png',
          option: 'Montañas y bosques'
        },
        {
          image: 'quiz_3.png',
          option: 'Ciudades con historia'
        },
        {
          image: 'quiz_4.png',
          option: 'Desiertos o selva'
        }
      ]
    },
    {
      question: '¿Qué no puede faltar en su itinerario?',
      options: [
        {
          image: 'quiz_5.png',
          option: 'Explorar museos y sitios históricos.'
        },
        {
          image: 'quiz_6.png',
          option: 'Relajarse en un spa o en la playa.'
        },
        {
          image: 'quiz_7.png',
          option: 'Probar comidas locales.'
        },
        {
          image: 'quiz_8.png',
          option: 'Practicar actividades al aire libre.'
        }
      ]
    },
    {
      question: '¿Qué tipo de alojamiento prefiere para su próximo viaje?',
      options: [
        {
          image: 'quiz_9.png',
          option: 'Hoteles de lujo.'
        },
        {
          image: 'quiz_10.png',
          option: 'Airbnb o casas locales.'
        },
        {
          image: 'quiz_11.png',
          option: 'Campamentos.'
        }
      ]
    },
    {
      question: '¿Cuál de estos continentes le gustaría visitar?',
      options: [
        {
          image: 'quiz_12.png',
          option: 'África'
        },
        {
          image: 'quiz_13.png',
          option: 'América'
        },
        {
          image: 'quiz_14.png',
          option: 'Asia'
        },
        {
          image: 'quiz_15.png',
          option: 'Europa'
        }
      ]
    }
  ]
  
  const setQuestion = (response) => {
    if (activeQuestion+1 <= questions.length-1) {
      setActiveQuestion(activeQuestion + 1);

      if (activeQuestion == 0) setResponse1(response)
      if (activeQuestion == 1) setResponse2(response)
      if (activeQuestion == 2) setResponse3(response)
    }
    if (activeQuestion == questions.length-1) {
      setIsLoading(true)
      setEmptyState(true)

      stQuiz(token, response1, response2, response3, response).then((res) => {
        console.log("response", res)
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    if (context && context.quiz && context.quiz[0]) {
      setEmptyState(true)
    }
  }, [context])

  console.log("context", context)

  return (
    <div className={`mc-quiz active-${activeQuestion+1}`}>
      <Container fullHeight className="container-quiz">
        <div className='mc-quiz__labels'>
          <VerticalCenter>
            <h3>
              {emptyState
                ? '¡Cuéntenos cómo sería su viaje soñado!'
                : '¿Cómo sería su viaje soñado? Cuéntenos y llévese una recompensa'
              }
              
            </h3>
            <div className='mc-page-content__separator'>
              <img src="/assets/images/separator.png" alt="Titulos" />
            </div>
            {emptyState
              ? <p>Ya puede descargar su guía de viaje con consejos prácticos para su siguiente aventura.</p>
              : <p><strong>Complete 4 preguntas rápidas y reciba una guía de viaje</strong> con consejos prácticos para sus próximas aventuras.  ¡Así de fácil!</p>
            }
          </VerticalCenter>
        </div>
        {emptyState
          ? <div className='mc-quiz__empty'>
              {isLoading
                ? <VerticalCenter className="center-content">
                    <img src='/assets/images/preloader_1.gif' alt='loader' />
                  </VerticalCenter>
                : <div className='mc-quiz__empty--bg'>
                  {/* <img src="/assets/images/empty-image.png" alt="" /> */}
                    <Button
                      icon="download"
                      type='primary'
                      text={'Descargue su guía de viaje'}
                      className="button-quiz"
                    />
                  </div>
              }
            </div>
          : <div className='mc-quiz__questions'>
              <div className='mc-quiz__questions--progress'>
                <div className='mc-quiz__questions--progress__state'></div>
              </div>
              {context &&
                <div className='mc-quiz__questions--items'>
                  {questions?.map((question, index) => {
                    return (
                      <div key={index} className={`mc-quiz__questions--items__item ${activeQuestion == index ? 'active' : ''}`}>
                        <div className='mc-quiz__questions--items__item--title'>{question.question}</div>
                        <div className='mc-quiz__questions--items__item--options'>
                          {question.options?.map((option, index) => {
                            return (
                              <div 
                                key={index} 
                                className={`mc-quiz__questions--items__item--option`} 
                                onClick={() => setQuestion(option.option)}
                              >
                                <div className='single-option'>
                                  <div className='single-option__image'>
                                    <img src={`/assets/images/${option.image}`} alt="" />
                                  </div>
                                  <div className='single-option__label'>{option.option}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
              
            </div>
        }
      </Container>
    </div>
  )
}

const mapState = ({ user: {token} }) => {
  return {
    token
  }
}

export default connect(mapState, {})(Quiz)