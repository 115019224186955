import React, { useRef } from 'react'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import Slider from "react-slick"

const FullScreenPostRedemtion = () => {
  const closeModal = () => {
    window.location.href = '/premios'
  }
  const sliderRef = useRef(null);

  const list = [
    {
      icon: "slider-gift.png",
      title: "Elija su premio",
      text: 'Una vez acceda al catálogo podrá buscar su bono favorito y redimirlo haciendo clic en "Redima su premio".'
    },
    {
      icon: "slider-check.png",
      title: "Descargue su bono",
      text: 'Confirme la descarga; el bono se guardará en la carpeta de descargas de su computador o dispositivo móvil.'
    }
  ]

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
  }  

  return (
    <div className='mc-post-redemtion'>
      <div className='mc-post-redemtion__overlay'></div>
      <div className='mc-post-redemtion__container'>
        <VerticalCenter>
          <div className='mc-post-redemtion__window'>
            <div className="close-post-redemtion" onClick={closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M17.8352 34.8906C27.0946 34.8906 34.6704 27.3148 34.6704 18.0554C34.6704 8.79606 27.0946 1.22021 17.8352 1.22021C8.57585 1.22021 1 8.79606 1 18.0554C1 27.3148 8.57585 34.8906 17.8352 34.8906Z" stroke="#040303" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.0713 22.8197L22.6001 13.291" stroke="#040303" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M22.6001 22.8197L13.0713 13.291" stroke="#040303" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <h3>Descargue así su premio</h3>
            <img src="/assets/images/separator.png" alt="Titulos" className='separator-title' />
            <div className='mc-post-redemtion__slider'>

              <Slider ref={sliderRef} {...settings}>
                {list.map((slide, index) => {
                  return (
                    <div key={index} className='mc-post-redemtion__slide'>
                      <img src={`/assets/images/${slide.icon}`} />
                      <h4>{slide.title}</h4>
                      <p>{slide.text}</p>
                    </div>
                  )
                })}
              </Slider>

            </div>
            <Button
              type="primary"
              text="Ir por mi premio"
              loading={false}
              status="active"
              link="/premios"
            />
          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default FullScreenPostRedemtion
