import React, { useEffect } from 'react'

import { arrNum } from '../../utils/functions'
import Icon from './Icon'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

function Pagger({
  page,
  pages,
  setPage,
  setPages,
  awards
}) {
  setPages(
    Math.ceil(awards.length / 6)
  )

  const changePage = (newPage) => {
    setPage(newPage)
  }

  const next = () => {
    if (page < pages) {
      setPage(page+1)
    }
    
  }
  const prev = () => {
    if (page > 1) {
      setPage(page-1)
    }
  }

  useEffect(() => {
    if (page == 3) {
      window.scrollTo({top: 500, left: 0, behavior: 'smooth' })
    }
    
  }, [page])

  return (
    <div className={`mc-pagger-container`}>
      <span className='mc-pagger-container__arrow left' onClick={prev}>
        <VerticalCenter>
          <Icon name="arrow" />
        </VerticalCenter>
      </span>
        {arrNum(Math.ceil(awards.length / 6)).map((index) => {
          return <button 
            key={index} 
            className={`mc-pagger-container__page slow_ani ${page === (index+1) ? 'active' : ''}`} 
            onClick={() => changePage(index+1)}
          >{index+1}</button>
        })}
      <span className='mc-pagger-container__arrow right' onClick={next}>
        <VerticalCenter>
          <Icon name="arrow" />
        </VerticalCenter>
      </span>
    </div>
  )
}

export default Pagger