import React, { useEffect, useRef, useState } from 'react'
import Container from '../../layouts/Container/Container';
import Icon from '../atoms/Icon';
import Button from '../atoms/Button';

const SectionCards = ({ items, title, subtitle, subtitle2, showGoalCounter, context, tabIndex }) => {
  const [counter, setCounter] = useState(0)
  const contentScrollRef = useRef(null)
  const SectionCardsRef = useRef(null)
  const rule = 'nocomplete'

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible")
          
        } else {
          entry.target.classList.remove("visible")
        }
      });
    }, { threshold: 0.04 });

    const cards = document.querySelectorAll(".card")
    cards.forEach((card) => observer.observe(card))

    const SectionCards = document.querySelectorAll(".SectionCards")
    SectionCards.forEach((item) => observer.observe(item))
  }, []);

  useEffect(() => {
    if (context && context.user) {
      const block = context ? context.block : 1
      const currentGoal = context ? context.user[`goal_amount_${block}`] : 0
      const currentTracing = context.tracing ? context.tracing[`amount_${block}`] : 0

      if (currentGoal) {
        if (currentTracing === currentGoal) {
          setCounter(1)
        } else if (currentTracing > currentGoal) {

          setCounter(
            rule === 'complete' ? Math.round(currentTracing / currentGoal) : (currentTracing / currentGoal).toFixed(1)
          )
        }
      }
    }
  }, [context])

  const scrollUp = () => {
    if (contentScrollRef.current) {
      const scrollContent = contentScrollRef.current
      const cardHeight = scrollContent.firstChild.offsetHeight
      scrollContent.scrollBy({
        top: -cardHeight,
        behavior: 'smooth',
      });
    }
  };

  const scrollDown = () => {
    if (contentScrollRef.current) {
      const scrollContent = contentScrollRef.current
      const cardHeight = scrollContent.firstChild.offsetHeight
      scrollContent.scrollBy({
        top: cardHeight,
        behavior: 'smooth',
      });
    }
  };
  

  return (
    <section ref={SectionCardsRef} className='SectionCards'>
      <Container className={'container-SectionCards'}>
        <article className="SectionContainer">
          <div className="sticky-title">
            <h4 className='line-title line-title-center-lg'>{title}</h4>
            {/* <div className="mc-page-content__separator no-desktop">
              <img src="/assets/images/separator.png" alt="Titulos" />
            </div> */}
            <p className='subtitle mb-4'>
              {subtitle}
            </p>
            {subtitle2 &&
              <p className='no-mobile subtitle2'>{subtitle2}</p>
            }
            {showGoalCounter &&
              <div className='mc-stair-slider__carousel--labels__tooltip no-mobile'>
                <Icon name="check" /> Ha cumplido su meta <strong>{counter > 0 ? counter : 'x'}</strong> veces
              </div>
            }
            {showGoalCounter &&
              <div className='mc-stair-slider__carousel--labels__cta no-mobile'>
                <Button
                  type='primary'
                  text={'Quiero el premio mayor'}
                  link={'/premios-mayor'}
                />
              </div>
            }
            <div style={{bottom: showGoalCounter ? '-50px': '-150px'}} className="controls">
              <button className='hoverArrows' onClick={scrollUp} ><Icon name='arrow-up' /></button>
              <button className='hoverArrows' onClick={scrollDown} ><Icon name='arrow-down' /></button>
            </div>
          </div>

          <div className="scroll-content" ref={contentScrollRef}>
            {
              items && items.length > 0 &&
              items.filter(itm => {
                if (tabIndex && itm.location) {
                  return itm.location === tabIndex
                } else {
                  return itm
                }
              }).map((item, i) => (
                <>
                  <div className="card" key={i}>
                    <img src={`/assets/mayor-award/${item.cardImg}`} alt={item.cardtitle} />
                    <div className="content-body-card">
                      <h3 className="card-title">{item.cardtitle}</h3>
                      <p className="card-description">{item.cardDescription}</p>
                    </div>
                  </div>
                </>

              ))
            }
          </div>

          <div className='desktop-styles'>
            {subtitle2 &&
              <p className='no-desktop'>{subtitle2}</p>
            }
            {showGoalCounter &&
              <div className='mc-stair-slider__carousel--labels__tooltip no-desktop'>
                <Icon name="check" /> Ha cumplido su meta <strong> {counter > 0 ? counter : 'x'} </strong> veces
              </div>
            }
            {showGoalCounter &&
              <div className='mc-stair-slider__carousel--labels__cta no-desktop'>
                <Button
                  type='primary'
                  text={'Quiero el premio mayor'}
                  link={'/premios-mayor'}
                />
              </div>
            }
          </div>
        </article>
      </Container>
    </section>
  )
}

export default SectionCards
