import React from 'react'

import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import SliderRotator from '../organisms/SliderRotator'
import Section from '../organisms/Section'
import useWidth from '../../hooks/useWidth'
// import SectionCards from '../molecules/SectionCards'

function Login({
  autologin,
  hash
}) {
  const { width } = useWidth()

  return (
    <div className='mc-page login slow_ani'>

      <div className='mc-login__container' style={{ "backgroundImage": `url(/assets/images/login-bg.png)` }}>
        <div className='mc-login__header'>
          <Container fullHeight={true}>
            <VerticalCenter>
              <img src={`/assets/images/login_banner.png`} />
            </VerticalCenter>
          </Container>
        </div>
        <Container>
          <LoginForm
            title="<strong>¡Haga su jugada maestra!</strong> Use su Tarjeta de Crédito Davivienda Mastercard, supere sus metas y disfrute de los premios."
            type="credentials"
            fields={[
              { field: "document", label: "Ingrese los últimos 5 dígitos de su documento", placeholder: "12345", maxlength: 5, separated: false },
              { field: "birthdate", label: "Fecha de nacimiento", placeholder: "dd/mm/aaaa" },
              { field: "terms", label: "He leído y acepto los", termsText: "términos y condiciones.", termsLink: "/terminos-y-condiciones" }
            ]}
            buttonText="Ingresar"
            background="#fff"
            autologin={autologin}
            hash={hash}
          />
        </Container>

        {width > 992 &&
          <img src={`/assets/images/banner-login2.png`} />
        }
      </div>



      <Section title="¿Cómo ganar?">
        <SliderRotator
          slidesToScroll={1}
          dots
          arrows
          mobileItemsToShow={1}
          desktopItemsToShow={3}
          className={'login-slider'}
          list={
            [
              {
                id: 1,
                image: `slider_1.png`,
                title: "Llegue a la meta con su tarjeta",
                desc: "Compre con su Tarjeta de Crédito Davivienda Mastercard, cumpla su meta y podrá ganar bonos y participar por el premio mayor."
              },
              {
                id: 2,
                image: `slider_2.png`,
                title: "Redima y disfrute",
                desc: "Hasta dos bonos podrán ser suyos logrando sus metas asignadas y aprovechando los beneficios de su tarjeta."
              },
              {
                id: 3,
                image: `slider_3.png`,
                title: "¡Elija el viaje que prefiera!",
                desc: "Logre su meta más veces que los demás y podrá recorrer <strong>La Patagonia</strong>, cenar en <strong>la Torre Eiffel</strong> o vivir una <strong>Carrera de automovilismo en México.</strong>"
              }
            ]
          }
        />
      </Section>

      {/* <SectionCards
        items={
          [
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-1.png',
              cardtitle: 'París para dos',
              cardDescription: 'Descubra la magia de este destino con su persona favorita.'
            },
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-2.png',
              cardtitle: 'Alojamiento',
              cardDescription: '5 días y 4 noches llenos de historia y cultura.'
            },
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-3.png',
              cardtitle: 'Experiencias únicas',
              cardDescription: 'Disfrutará de la Torre Eiffel, Museo del Louvre y mucho más. ¡Lo mejor de la ciudad espera por usted!'
            },
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-4.png',
              cardtitle: 'Alimentación incluida',
              cardDescription: 'Tendrá 2 comidas diarias, desayuno y cena o almuerzo.'
            },
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-1.png',
              cardtitle: 'París para dos',
              cardDescription: 'Descubra la magia de este destino con su persona favorita.'
            },
            {
              cardImg: 'card-paris-premio-premio-mayor-cascada-2.png',
              cardtitle: 'Alojamiento',
              cardDescription: '5 días y 4 noches llenos de historia y cultura.'
            },

          ]
        }
      /> */}

    </div>
  )
}
export default Login