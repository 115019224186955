import React from 'react'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import useWidth from '../../hooks/useWidth'

const FullScreenSplash = ({
  setOpenSplash,
  redirectPage
}) => {
  const { width } = useWidth()

  return (
    <div
      className='mc-splash' 
      style={{"backgroundImage": `url(/assets/images/bg-splash${width < 992 ? '-mobile' : ''}.png)`}}
    >
      <div className='mc-splash__logo'>
        <img src="/assets/images/logo_h.png" />
      </div>
      <div className='mc-splash__container'>
        <div className='mc-splash__container--left'>
          <VerticalCenter>
            <div className='mc-splash__container--title'>¡Registro exitoso!</div>
            <div className="mc-page-content__separator">
              <img src="/assets/images/separator.png" alt="Titulos" />
            </div>
            <div className='mc-splash__container--text'>
              <strong>Bienvenido a Compras Maestras para Todos.</strong> Conozca su meta, siga su progreso y descubra los premios que están a su alcance.
            </div>
            <Button
                type="primary"
                text="Comenzar ahora"
                loading={false}
                status="active"
                onClick={() => {
                  setOpenSplash(false);
                  window.location.href = redirectPage ? redirectPage : '/mecanica';
                }}
            />
          </VerticalCenter>
        </div>
        <div className='mc-splash__container--right'>
          <VerticalCenter>
            <img src="/assets/images/image-splash.png" />
          </VerticalCenter>
        </div>
      </div>
    </div>
  )
}

export default FullScreenSplash
