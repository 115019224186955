import React from 'react'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Container from '../../layouts/Container/Container'
import useWidth from '../../hooks/useWidth'


function CardBox({
  image,
  imageDesktop,
  title,
  desc,
  type,
  className,
  davipuntosAwards,
  name,
  description,
  subdescription,
  double_points,
  logo,
  banner,
  color,

}) {
  const { width } = useWidth()
console.log(color)
  return (
    <div className={`mc-cardbox-container ${type ? type : 'normal'} ${className ? className : ''} ${davipuntosAwards ? 'davipuntosAwards' : ''}`}>
      {
        davipuntosAwards ?
          <>
            <div className='LogoCardBox'>
                <figure className="logo">
                <img src={`/assets/davipuntos-logo/${logo}`} alt={`Logo ${name}`} />
                </figure>
                <div style={{background: color}} className="text-logo">
                  <h3>{name}</h3>
                </div>
            </div>
            
            <div className="content-cardBox">
              <div className="img-title">
                {
                  banner &&
                  <img src={`/assets/davipuntos-logo/chocolatera.png`} alt="" />
                }
                
              <div className="title-copy">
              <h4 className="title">{description}</h4>
              {
                subdescription &&
                <h5 className='subtitle'>Clase económica con aerolinea LATAM*</h5>
              }
              
              </div>
              </div>
              
              <div className="double-points">
                <p>{double_points}</p>
              </div>
              {/* <div className="normal-points">
                <p>{normal_points}</p>
              </div> */}
            </div>
          </>
          :
          <>

            {imageDesktop
              ? <img src={`/assets/images/${width < 992 ? image : imageDesktop}`} alt="Step image" />
              : image &&
              <img src={`/assets/images/${image}`} alt="Step image" />
            }
            {type
              ? <div className='mc-cardbox-container--fullContainer'>
                <Container fullHeight>
                  <VerticalCenter>
                    {title &&
                      <h2>{title}</h2>
                    }
                    {desc &&
                      <div dangerouslySetInnerHTML={{ __html: desc }} />
                    }
                  </VerticalCenter>
                </Container>
              </div>
              : <>
                {title &&
                  <h2>{title}</h2>
                }
                {desc &&
                  <div className='desc-paraTodos' dangerouslySetInnerHTML={{ __html: desc }} />
                }
              </>
            }
          </>
      }

    </div>
  )
}

export default CardBox