import React from 'react'
import Container from '../../layouts/Container/Container'
import { Link } from 'react-router-dom'

const SectionTYC = () => {
  return (
    <section className='SectionTYC'>
      <article>
        <Container>
          <div className="wrap-tyc">
            <div className="icon-tyc"><img src="/assets/images/icon-tyc.png" alt="" /></div>
            <p className='description-tyc'>¿Le gustaría conocer más detalles? <br />
              Visite nuestras <Link to='/preguntas-frecuentes'>preguntas frecuentes</Link> y lea los <Link to='/terminos-y-condiciones'>términos y condiciones</Link> para obtener más información.</p>
          </div>
        </Container>
      </article>
    </section>
  )
}

export default SectionTYC
