import React from 'react'
import Button from '../atoms/Button'

const SectionAssistanceServices = () => {
    // const list = [
    //     {
    //         id: 1,
    //         image: `assis-1.png`,
    //         title: "¿Qué son?",
    //         desc: "Nuestras asistencias son servicios gratuitos que le brindan sus Tarjetas de Crédito Davivienda Mastercard en alianza con Servicios Bolívar."
    //     },
    //     {
    //         id: 2,
    //         image: `assis-2.png`,
    //         title: "¿Qué incluyen?",
    //         desc: "Disfrute de un paquete anual que incluye cinco servicios diseñados para facilitar su vida cuando más lo necesite."
    //     },
    //     {
    //         id: 3,
    //         image: `assis-3.png`,
    //         title: "Beneficios para usted",
    //         desc: "Aproveche los servicios para clientes: Clásico, Jóvenes, Premium y Persona natural."
    //     },
    // ]

    return (
        <div className='SectionAssistanceServices'>
        

                {/* <h2>Conozca las asistencias Davivienda</h2>
                <div className="wrap-cards-assistance">
                    {
                        list.map((item, index) => (
                            <div key={index} className="card-assistance">
                                <figure className="img_assistance">
                                    <img src={`/assets/images/${item.image}`} alt={item.title} />
                                </figure>
                                <div className="content-assistance">
                                    <h3>{item.title}</h3>
                                    <p dangerouslySetInnerHTML={{__html:item.desc}}></p>
                                </div>
                            </div>
                        ))
                    }

                </div> */}
                <p>Términos y condiciones sujetos a cada comercio.</p>
                <div className='btn-allies-assistance'>
                    <Button
                        type='primary'
                        text='Conocer más'
                        link={'https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html'}
                        status={'active'}
                        external
                    />
                    
                </div>
        </div>
    )
}

export default SectionAssistanceServices
