import React from 'react'
import { Link } from "react-router-dom"

import AlliedBox from '../molecules/AlliedBox'
import { sendDataLayer } from '../../utils/functions'

function AlliancesGallery({
  alliedBoxType,
  itemsPerLine,
  limit,
  termsOnModal,
  limitButtonText,
  list,
  termText,
  termLink
}) {

  const layer = () => {
    sendDataLayer({datalayer: { event: 'steps', text: 'Ver todos los aliados', link: '/alianzas' },repeat: false})
  }

  return (
    <div className={`mc-alliances-container mc-alliances-column-${itemsPerLine}`}>
      
      {list &&
        <div className="mc-alliances-container__items">
          {list.filter((item, i) => {

            return limit ? i < limit ? true : false : true
          }).map((item, index) => {

            return (
              <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType}`}>
                <AlliedBox
                  type={alliedBoxType}
                  allied={item}
                  termLink={termLink}
                  termText={termText}
                  termsOnModal={termsOnModal}
                  termsData={item.termsData}
                />
              </div>
            )
          })}
        </div>
      }

      {limit && limitButtonText &&
        <div className="mc-alliances-container__actions" onClick={layer}>
          <Link to="/alianzas">{limitButtonText}</Link>
        </div>
      }

    </div>
  )
}

export default AlliancesGallery